import React, { Component } from 'react';
import Header from '../Header';
import Footer from '../Footer';

class Home extends Component {

    render() {
        return (
            <div className="App">

                {/* Start Preloader Area */}
                <div className="preloader">
                    <div className="spinner" />
                </div>
                {/* End Preloader Area */}
                <Header />
                {/* Start IoT Main Banner */}
                <section className="iot-main-banner" id="home">
                    <div className="container">
                        <div className="iot-banner-content">
                            <span>Dịch vụ trực tuyến</span>
                            <h2>Hệ thống cảnh báo điểm yếu và rà soát lỗ hổng bảo mật tự động</h2>
                            <p />
                            <a href="/app/auth/register" className="btn btn-primary">Đăng ký ngay</a>
                            <a href="/app/authorize/login" className="btn btn-primary login-hidden">Đăng nhập</a>
                        </div>
                        <div className="iot-banner-image">
                            <img src="assets/img/iot-banner-image/1.png" className="wow fadeInUp" data-wow-delay="0.8s" alt="image" />
                            <img src="assets/img/iot-banner-image/2.png" className="wow zoomIn" alt="image" />
                        </div>
                        <div className="animate-border">
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                    </div>
                </section>
                {/* End IoT Main Banner */}
                {/* Start IoT Partner Area */}
                <section className="iot-partner-area bg-f9fafb">
                    <div className="container">
                        <div className="row">
                            <div className="partner-slides">
                                <div className="col-lg-12 col-md-12">
                                    <div className="single-iot-partner">
                                        <a href="#">
                                            <img src="assets/img/slider-down-header/1.png" alt="image" />
                                            <img src="assets/img/slider-down-header/1.png" alt="image" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="single-iot-partner">
                                        <a href="#">
                                            <img src="assets/img/slider-down-header/2.png" alt="image" />
                                            <img src="assets/img/slider-down-header/2.png" alt="image" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="single-iot-partner">
                                        <a href="#">
                                            <img src="assets/img/slider-down-header/3.png" alt="image" />
                                            <img src="assets/img/slider-down-header/3.png" alt="image" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="single-iot-partner">
                                        <a href="#">
                                            <img src="assets/img/slider-down-header/4.png" alt="image" />
                                            <img src="assets/img/slider-down-header/4.png" alt="image" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="single-iot-partner">
                                        <a href="#">
                                            <img src="assets/img/slider-down-header/5.png" alt="image" />
                                            <img src="assets/img/slider-down-header/5.png" alt="image" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="single-iot-partner">
                                        <a href="#">
                                            <img src="assets/img/slider-down-header/6.png" alt="image" />
                                            <img src="assets/img/slider-down-header/6.png" alt="image" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="single-iot-partner">
                                        <a href="#">
                                        </a><a href="#">
                                            <img src="assets/img/slider-down-header/7.png" alt="image" />
                                            <img src="assets/img/slider-down-header/7.png" alt="image" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End IoT Partner Area */}
                {/* Start IoT Why Choose Us Area */}
                <div id="service" style={{ marginBottom: '120px' }} />
                <section className="iot-why-choose-us ptb-80 pb-0">
                    <div className="container">
                        <div className="section-title">
                            <h2>Các dịch vụ trực tuyến chúng tôi đang cung cấp</h2>
                            <div className="bar" />
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-iot-box">
                                    <div className="icon">
                                        <img src="assets/img/icon2.png" alt="image" />
                                    </div>
                                    <h3>Vulnerability Alert</h3>
                                    <p>Hệ thống theo dõi, tracking, cảnh báo sớm các điểm yếu an toàn thông tin với các phần mềm và
                    phiên bản cố định đối với các đơn vị.</p>
                                    <a href="#"><i data-feather="arrow-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div className="single-iot-box">
                                    <div className="icon">
                                        <img src="assets/img/icon3.png" alt="image" />
                                    </div>
                                    <h3>Remote Vulnerability Scanning</h3>
                                    <p>Hệ thống tự động rà quét, đánh giá các điểm yếu an toàn thông tin trên hệ thống để định kỳ,
                    báo cáo hàng tuần cho quản trị hệ thống.</p>
                                    <a href="#"><i data-feather="arrow-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-iot-box">
                                    <div className="icon">
                                        <img src="assets/img/icon1.png" alt="image" />
                                    </div>
                                    <h3>Threat Intelligence Basic</h3>
                                    <p>Hệ thống cảnh báo, theo dõi và tra cứu các mối nguy hại, tiềm tàng, phát hiện các kết nối
                    độc lại, nguy cơ bị tấn công mạng.</p>
                                    <a href="#"><i data-feather="arrow-right" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End IoT Why Choose Us Area */}
                {/* Start IoT Services Area */}
                <section className="iot-services-area ptb-80">
                    <div className="container">
                        <div className="section-title">
                            <h2>Các điểm đặc biệt của hệ thống dịch vụ trực tuyến của chúng tôi</h2>
                            <div className="bar" />
                            <p />
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-iot-services">
                                    <div className="icon">
                                        <i className="flaticon-software" />
                                    </div>
                                    <h3>Dễ dàng triển khai <br />và sử dụng</h3>
                                    <p>Cấu hình và giám sát an toàn thông tin từ xa với hệ thống của mình qua giao diện Web. Không
                    yêu cầu đầu tư thêm thiết bị phần cứng hay chi phí vận hành.</p>
                                    <a href="#"><i data-feather="arrow-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-iot-services">
                                    <div className="icon">
                                        <i className="flaticon-gear" />
                                    </div>
                                    <h3>Tiết kiệm chi phí <br />của Doanh nghiệp</h3>
                                    <p>Giải pháp phát triển theo mô hình SaaS giúp doanh nghiệp giảm thiểu tối đa chi phí đối của
                    doanh nghiệp khi triển khai các giải pháp bảo đảm an toàn thông tin </p>
                                    <a href="#"><i data-feather="arrow-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-iot-services">
                                    <div className="icon">
                                        <i className="flaticon-skyline" />
                                    </div>
                                    <h3>Cảnh báo sớm <br />rủi ro, nguy cơ</h3>
                                    <p>Hệ thống sẽ thực hiện cảnh báo sớm các nguy cơ, rủi ro tấn công mạng vào tổ chức ngay sau khi
                    phát hiện hoặc có các công bố mới qua Email, SMS.</p>
                                    <a href="#"><i data-feather="arrow-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-iot-services">
                                    <div className="icon">
                                        <i className="flaticon-car-insurance" />
                                    </div>
                                    <h3>Nguồn dữ liệu <br />đa dạng, phong phú</h3>
                                    <p>Dữ liệu được tổng hợp từ các tổ chức Quốc tế, Việt Nam, từ các sensor, honeypot,...từ đó phân
                    tích để có các cảnh báo sớm nhất, cụ thể nhất.</p>
                                    <a href="#"><i data-feather="arrow-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-iot-services">
                                    <div className="icon">
                                        <i className="flaticon-factory" />
                                    </div>
                                    <h3>Thông tin kỹ thuật <br />nóng cập nhật</h3>
                                    <p>Liên tục cập nhật các thông tin kỹ thuật mới về nguy cơ tấn công mạng đối với Việt Nam. Đặc
                    biệt đối với khối Tài chính, Ngân hàng, Năng lượng và CIIP khác.</p>
                                    <a href="#"><i data-feather="arrow-right" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-iot-services">
                                    <div className="icon">
                                        <i className="flaticon-chip" />
                                    </div>
                                    <h3>Hỗ trợ <br />24/7/365</h3>
                                    <p>Thực hiện giám sát liên tục theo thời gian thực. Đội ngũ hỗ trợ kỹ thuật sẵn sàng hỗ trợ khi
                    có các yêu cầu bổ sung của dịch vụ.</p>
                                    <a href="#"><i data-feather="arrow-right" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End IoT Services Area */}
                {/* Start IoT CTA Area */}
                <div id="about" style={{ marginBottom: '150px' }} />
                <section className="iot-cta-area bg-0f054b">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="cta-iot-img">
                                    <img src="assets/img/cta-shape2.png" className="wow fadeInUp" data-wow-delay="0.6s" alt="image" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="cta-iot-content">
                                    <h3>Chúng tôi là ?</h3>
                                    <p>Trung tâm Giám sát an toàn không gian mạng quốc gia có chức năng giám sát trung tâm, là đầu
                                    mối kỹ thuật về giám sát, hỗ trợ bảo đảm ATTT cho người dân, doanh nghiệp và các hệ thống
                    thông tin của Đảng, Nhà nước theo quy định của pháp luật.</p>
                                    <p>Thực hiện quản lý, vận hành các hệ thống số liệu, cơ sở dữ liệu về ATTT, hệ thống kỹ thuật
                                    bảo đảm ATTT mạng quốc gia phục vụ công tác quản lý nhà nước và thực thi pháp luật về
                    ATTT...</p>
                                    <a href="https://thuvienphapluat.vn/van-ban/Cong-nghe-thong-tin/Quyet-dinh-1616-QD-BTTTT-2018-quyen-han-Trung-tam-Giam-sat-an-toan-khong-gian-mang-quoc-gia-400477.aspx" className="btn btn-primary" target="_blank">Tìm hiểu thêm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="circle-box"><img src="assets/img/circle.png" alt="image" /></div>
                    <div className="cta-shape"><img src="assets/img/cta-shape.png" alt="image" /></div>
                </section>
                {/* End IoT CTA Area */}
                {/* Start Cloud Hosting Services */}
                {/* Start Cloud Hosting Services */}
                <div id="feature" style={{ marginBottom: '100px' }} />
                <section className="services-area ptb-80 bg-f7fafd">
                    <div className="container">
                        <div className="row h-100 justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-12 services-content">
                                <div className="section-title">
                                    <h2> Dịch vụ 1: Vulnerability Alert</h2>
                                    <div className="bar" />
                                    <p>Dịch vụ 01: Gói dịch vụ sẽ bao
                    gồm:</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="database" />Cảnh báo sớm cụ thể và chi tiết thể đến từng Product/Version của tổ chức
                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="trending-up" />Cung cấp thông tin giải pháp, khuyến nghị với từng lỗ hổng, điểm yếu
                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="globe" />Cung cấp các "bản tin kỹ thuật riêng" cho nhóm tổ chức CIIP
                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="file" />Các gói dịch vụ phong phú đa dạng theo từng nhu cầu của tổ chức
                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="trending-up" />Hỗ trợ cảnh báo tức thì khi có thông tin vào Email và SMS
                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="folder" />Cung cấp cảnh báo chuyên sâu với những lỗ hổng trên diện rộng
                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 services-right-image">
                                <img src="assets/img/services-right-image/book-self.png" className="wow fadeInDown" data-wow-delay="0.6s" alt="book-self" />
                                <img src="assets/img/services-right-image/box.png" className="wow fadeInUp" data-wow-delay="0.6s" alt="box" />
                                <img src="assets/img/services-right-image/chair.png" className="wow fadeInLeft" data-wow-delay="0.6s" alt="chair" />
                                <img src="assets/img/services-right-image/cloud.png" className="wow zoomIn" data-wow-delay="0.6s" alt="cloud" />
                                <img src="assets/img/services-right-image/cup.png" className="wow bounceIn" data-wow-delay="0.6s" alt="cup" />
                                <img src="assets/img/services-right-image/flower-top.png" className="wow fadeInDown" data-wow-delay="0.6s" alt="flower" />
                                <img src="assets/img/services-right-image/head-phone.png" className="wow zoomIn" data-wow-delay="0.6s" alt="head-phone" />
                                <img src="assets/img/services-right-image/monitor.png" className="wow fadeInUp" data-wow-delay="0.6s" alt="monitor" />
                                <img src="assets/img/services-right-image/mug.png" className="wow rotateIn" data-wow-delay="0.6s" alt="mug" />
                                <img src="assets/img/services-right-image/table.png" className="wow fadeInUp" data-wow-delay="0.6s" alt="table" />
                                <img src="assets/img/services-right-image/tissue.png" className="wow zoomIn" data-wow-delay="0.6s" alt="tissue" />
                                <img src="assets/img/services-right-image/water-bottle.png" className="wow zoomIn" data-wow-delay="0.6s" alt="water-bottle" />
                                <img src="assets/img/services-right-image/wifi.png" className="wow fadeInLeft" data-wow-delay="0.6s" alt="wifi" />
                                <img src="assets/img/services-right-image/cercle-shape.png" className="bg-image rotateme" alt="shape" />
                                <img src="assets/img/why-choose-us-image/database.png" className="wow fadeInUp" data-wow-delay="0.6s" alt="main-pic" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Cloud Hosting Services */}
                {/* Start Design & Development Services */}
                <section className="services-area ptb-80">
                    <div className="container">
                        <div className="row h-100 justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-12 services-left-image">
                                <img src="assets/img/services-left-image/big-monitor.png" className="wow fadeInDown" data-wow-delay="0.6s" alt="big-monitor" />
                                <img src="assets/img/services-left-image/creative.png" className="wow fadeInUp" data-wow-delay="0.6s" alt="creative" />
                                <img src="assets/img/services-left-image/developer.png" className="wow fadeInLeft" data-wow-delay="0.6s" alt="developer" />
                                <img src="assets/img/services-left-image/flower-top.png" className="wow zoomIn" data-wow-delay="0.6s" alt="flower-top" />
                                <img src="assets/img/services-left-image/small-monitor.png" className="wow bounceIn" data-wow-delay="0.6s" alt="small-monitor" />
                                <img src="assets/img/services-left-image/small-top.png" className="wow fadeInDown" data-wow-delay="0.6s" alt="small-top" />
                                <img src="assets/img/services-left-image/table.png" className="wow zoomIn" data-wow-delay="0.6s" alt="table" />
                                <img src="assets/img/services-left-image/target.png" className="wow fadeInUp" data-wow-delay="0.6s" alt="target" />
                                <img src="assets/img/services-left-image/cercle-shape.png" className="bg-image rotateme" alt="shape" />
                                <img src="assets/img/services-left-image/main-pic.png" className="wow fadeInUp" data-wow-delay="0.6s" alt="main-pic" />
                            </div>
                            <div className="col-lg-6 col-md-12 services-content">
                                <div className="section-title">
                                    <h2>Dịch vụ 02: Remote Vulnerability Scanning</h2>
                                    <div className="bar" />
                                    <p>Dịch vụ 2 : Gói dịch vụ sẽ bao gồm : </p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="layout" />Tự động rà quét, đánh giá điểm yếu cho các IP public
                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="code" />Đặt lịch định kỳ để rà soát và đánh giá IP của tổ chức
                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="smartphone" />Có báo cáo định kỳ theo tuần/tháng tùy nhu cầu
                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="code" />Cung cấp khuyến nghị, giải pháp cho từng lỗ hổng đã phát
                      hiện
                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="smartphone" />Quản trị và báo cáo trực quan trên nền tảng Web
                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="pen-tool" />Hỗ trợ cảnh báo tức thì khi có thông tin vào Email và
                      SMS
                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Design & Development Services */}
                <div id="feature" style={{ marginBottom: '100px' }} />
                <section className="services-area ptb-80 bg-f7fafd">
                    <div className="container">
                        <div className="row h-100 justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-12 services-content">
                                <div className="section-title">
                                    <h2> Dịch vụ 3: Threat Intelligence Basic </h2>
                                    <div className="bar" />
                                    <p>Dịch vụ 3: Gói dịch vụ sẽ bao
                    gồm:</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="database" />Cung cấp IoC của mã độc, các mạng botnet;
                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="trending-up" />Cung cấp IoC của các chiến dịch tấn công APT
                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="globe" />Hỗ trợ tích hợp vào các giải pháp thương mại
                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="globe" />Cung cấp API tích hợp với hệ thống
                    </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="box">
                                            <i data-feather="file" />Giám sát các kết nối độc hại, nguy cơ với dải địa chỉ IP của tổ chức
                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 services-right-image">
                                <img src="assets/img/services-right-image/book-self.png" className="wow fadeInDown" data-wow-delay="0.6s" alt="book-self" />
                                <img src="assets/img/services-right-image/box.png" className="wow fadeInUp" data-wow-delay="0.6s" alt="box" />
                                <img src="assets/img/services-right-image/chair.png" className="wow fadeInLeft" data-wow-delay="0.6s" alt="chair" />
                                <img src="assets/img/services-right-image/cloud.png" className="wow zoomIn" data-wow-delay="0.6s" alt="cloud" />
                                <img src="assets/img/services-right-image/cup.png" className="wow bounceIn" data-wow-delay="0.6s" alt="cup" />
                                <img src="assets/img/services-right-image/flower-top.png" className="wow fadeInDown" data-wow-delay="0.6s" alt="flower" />
                                <img src="assets/img/services-right-image/head-phone.png" className="wow zoomIn" data-wow-delay="0.6s" alt="head-phone" />
                                <img src="assets/img/services-right-image/monitor.png" className="wow fadeInUp" data-wow-delay="0.6s" alt="monitor" />
                                <img src="assets/img/services-right-image/mug.png" className="wow rotateIn" data-wow-delay="0.6s" alt="mug" />
                                <img src="assets/img/services-right-image/table.png" className="wow fadeInUp" data-wow-delay="0.6s" alt="table" />
                                <img src="assets/img/services-right-image/tissue.png" className="wow zoomIn" data-wow-delay="0.6s" alt="tissue" />
                                <img src="assets/img/services-right-image/water-bottle.png" className="wow zoomIn" data-wow-delay="0.6s" alt="water-bottle" />
                                <img src="assets/img/services-right-image/wifi.png" className="wow fadeInLeft" data-wow-delay="0.6s" alt="wifi" />
                                <img src="assets/img/services-right-image/cercle-shape.png" className="bg-image rotateme" alt="shape" />
                                <img src="assets/img/why-choose-us-image/database.png" className="wow fadeInUp" data-wow-delay="0.6s" alt="main-pic" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* Start Footer Area */}
                <Footer />
                {/* End Footer Area */}
            </div>

        );
    }
}

export default Home;