import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Header extends Component {

    render() {
        return (
            <React.Fragment>
                < header id="header" >
                    <div className="startp-mobile-nav">
                        <div className="logo">
                            <Link to="/"><img src="assets/img/logo.png" alt="logo" width="80px"/></Link>
                        </div>
                    </div>
                    <div className="startp-nav">
                        <div className="container">
                            <nav className="navbar navbar-expand-md navbar-light">
                                <Link className="navbar-brand" to="/"><img src="assets/img/logo.png" alt="logo" /></Link>
                                <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                    <ul className="navbar-nav nav ml-auto">
                                        <li className="nav-item"><Link to="#home" className="nav-link ">Trang chủ</Link></li>
                                        <li className="nav-item"><Link to="#about" className="nav-link ">Chúng tôi là</Link></li>
                                        <li className="nav-item"><Link to="#service" className="nav-link">Dịch vụ</Link></li>
                                        <li className="nav-item"><Link to="#contact" className="nav-link">Liên hệ</Link></li>
                                        <li className="nav-item"><Link to="/help" className="nav-link login-hidden">Hỗ trợ</Link></li>
                                    </ul>
                                </div>
                                <div className="others-option">
                                    <Link to="/help" className="btn btn-light">Hướng dẫn</Link>
                                    <a href="/app/authorize/login" className="btn btn-primary">Đăng nhập</a>
                                </div>
                            </nav>
                        </div>
                    </div>
                </ header>
            </React.Fragment>
        );
    }
}

export default Header;