import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './views/Home';
import Help from './views/Help';


class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          {/* {/* <Route exact path="/dashboard" component={MiddleLayout} /> */}
          <Route exact path="/help" component={Help} /> 
        </Switch>
        {/* {
      //         renderRoutes(router)
      
      //       } */}
      </BrowserRouter >

    );
  }
}

export default App;
