import React, { Component } from 'react';
import FooterClone from '../Footerclone';
import HeaderClone from '../Headerclone';

class Help extends Component {

    render() {
        return (
            <React.Fragment>
                <HeaderClone />
                {/* <section className="iot-main-banner" id="home">
                    <div className="container">
                       
                   
                    </div>
                </section> */}
                {/* End IoT Main Banner */}

                {/* Start IoT Why Choose Us Area */}
                {/* <section className="iot-main-banner iot-why-choose-us" id="home">
                    <div className="container">
                        <div id="service" style={{ marginBottom: '120px' }} />

                        <div className="container">
                            <div className="section-title">
                                <h2>Hỗ trợ </h2>
                                <div className="bar" />
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="single-iot-box">
                                        <div className="icon">
                                            <img src="assets/img/icon2.png" alt="image" />
                                        </div>
                                        <h3>Hướng dẫn đăng ký tài khoản</h3>
                                        <a href="../HuongDanDangKyTaiKhoan.pdf" target="_blank"><i class="fas fa-share-square"></i></a>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 offset-lg-0 offset-md-3">
                                    <div className="single-iot-box">
                                        <div className="icon">
                                            <img src="assets/img/icon3.png" alt="image" />
                                        </div>
                                        <h3>Hướng dẫn sử dụng hệ thống</h3>
                                        <a href="../HuongDanSuDung.pdf" target="_blank"><i class="fas fa-share-square"></i></a>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>
 */}

                <section className="iot-main-banner iot-services-area ptb-80">
                    <div className="container">
                        <div className="section-title">
                            <h2>Hỗ trợ</h2>
                            <div className="bar" />
                            <p />
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-iot-services">
                                    <div className="icon">
                                        <i class="far fa-registered"></i>
                                    </div>

                                    <h3>Hướng dẫn đăng ký tài khoản</h3>
                                    <a href="../HuongDanDangKyTaiKhoan.pdf" target="_blank"><i class="fas fa-share-square"></i></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-iot-services">
                                    <div className="icon">
                                        <i className="flaticon-gear" />
                                    </div>
                                    <h3>Hướng dẫn sử dụng hệ thống</h3>
                                    <a href="../HuongDanSuDung.pdf" target="_blank"><i class="fas fa-share-square"></i></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-iot-services">
                                    <div className="icon">
                                        <i class="fab fa-facebook "></i>
                                    </div>
                                    <h3>Hướng dẫn hỗ trợ trực tiếp</h3>
                                    <a href="https://www.facebook.com/messages/t/govSOC" target="_blank"><i class="fab fa-facebook-messenger"></i></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <br /><br /><br />
                {/* End IoT Why Choose Us Area */}


                {/* Start Cloud Hosting Services */}
                {/* Start Cloud Hosting Services */}

                <FooterClone />
            </React.Fragment>

        );
    }
}

export default Help;