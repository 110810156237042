import React, { Component } from 'react';

class FooterClone extends Component {

    render() {
        return (
            <React.Fragment>
                <footer className="footer-area bg-f7fafd">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-footer-widget">
                                    <div className="logo">
                                        <a href="#"><img src="assets/img/logo.png" alt="logo" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-footer-widget pl-5">
                                    <h3>Company</h3>
                                    <ul className="list">
                                        <li><a href="#about">Về chúng tôi</a></li>
                                        <li><a href="#service">Dịch vụ cung cấp</a></li>
                                        <li><a href="#feature">Dịch vụ</a></li>

                                    </ul>
                                </div>
                            </div>
                            {/* <div className="col-lg-3 col-md-6">
                                <div className="single-footer-widget">
                                    <h3>Support</h3>
                                    <ul className="list">
                                        <li><a>FAQ's</a></li>
                                        <li><a>Privacy Policy</a></li>
                                        <li><a>Terms &amp; Condition</a></li>
                                        <li><a>Community</a></li>
                                        <li><a href="#contact">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-lg-4 col-md-6" id="contact">
                                <div className="single-footer-widget">
                                    <h3>Địa chỉ liên hệ</h3>
                                    <ul className="footer-contact-info">
                                        <li><i data-feather="map-pin" /> Tầng 16 - 115 Trần Duy Hưng - Cầu Giấy - Hà Nội</li>
                                        <li><i data-feather="mail" /> Email: <a>services@ais.gov.vn</a></li>
                                        <li><i data-feather="phone-call" /> Phone: <a>024 32091616</a></li>
                                    </ul>
                                    <ul className="social-links">
                                        <li><a className="facebook" href="https://www.facebook.com/govSOC/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                        {/* <li><a class="twitter"><i data-feather="twitter"></i></a></li> */}
                                        {/* <li><a className="instagram"><i data-feather="instagram" /></a></li>
                                        <li><a className="linkedin"><i data-feather="linkedin" /></a></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="copyright-area">
                                    <p>Copyright @2019 Trung tâm Giám sát an toàn không gian mạng quốc gia. All rights reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="assets/img/map.png" className="map" alt="map" />
                    <div className="shape1"><img src="assets/img/shape1.png" alt="shape" /></div>
                    <div className="shape8 rotateme"><img src="assets/img/shape2.svg" alt="shape" /></div>
                </footer>
            </React.Fragment>
        );
    }
}

export default FooterClone;