import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom'
class HeaderClone extends Component {

    render() {
        return (
            <React.Fragment>
                < header id="header" >
                    <div className="startp-mobile-nav">
                        <div className="logo">
                            <a href="/"><img src="assets/img/logo.png" alt="logo" /></a>
                        </div>
                    </div>
                    <div className="startp-nav">
                        <div className="container">
                            <nav className="navbar navbar-expand-md navbar-light">
                                <a className="navbar-brand" href="/"><img src="assets/img/logo.png" alt="logo" /></a>
                                <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                    <ul className="navbar-nav nav ml-auto">
                                        <li className="nav-item"><a href="/" className="nav-link ">Trang chủ</a></li>
                                        <li className="nav-item"><a href="/" className="nav-link ">Chúng tôi là</a></li>
                                        <li className="nav-item"><a href="/" className="nav-link">Dịch vụ</a></li>
                                        <li className="nav-item"><a href="/" className="nav-link">Liên hệ</a></li>
                                        <li className="nav-item"><a href="/" className="nav-link login-hidden">Hỗ trợ</a></li>
                                    </ul>
                                </div>
                                <div className="others-option">
                                    <a href="/help" className="btn btn-light">Hướng dẫn</a>
                                    <a href="/app/authorize/login" className="btn btn-primary">Đăng nhập</a>
                                </div>
                            </nav>
                        </div>
                    </div>
                </ header>
            </React.Fragment>
        );
    }
}

export default HeaderClone;